@font-face {
    font-family: 'Amazon Ember';
    src: local('Amazon Ember'), url(../assets/fonts/ember/AmazonEmber_W_Rg.woff2) format("embedded-opentype"), /* chrome firefox */
    url(../assets/fonts/ember/AmazonEmber_W_Rg.woff2) format("embedded-opentype"), /* chrome firefox */
    url(../assets/fonts/ember/AmazonEmber_Rg.ttf) format("embedded-opentype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url(../assets/fonts/ember/AmazonEmber_W_Rg.eot?#iefix) format("embedded-opentype"), /* IE6-IE8 */
    url(../assets/fonts/ember/AmazonEmber_W_Rg.eot) format('woff'),  /* IE9*/
  }
  
  /* fallback */
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/material/material_rg.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }